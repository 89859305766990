exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-agenda-tsx": () => import("./../../../src/pages/agenda.tsx" /* webpackChunkName: "component---src-pages-agenda-tsx" */),
  "component---src-pages-articulos-articulo-tsx": () => import("./../../../src/pages/articulos/articulo.tsx" /* webpackChunkName: "component---src-pages-articulos-articulo-tsx" */),
  "component---src-pages-articulos-big-push-tsx": () => import("./../../../src/pages/articulos/big_push.tsx" /* webpackChunkName: "component---src-pages-articulos-big-push-tsx" */),
  "component---src-pages-articulos-ciencia-tecnologia-innovacion-tsx": () => import("./../../../src/pages/articulos/ciencia_tecnologia_innovacion.tsx" /* webpackChunkName: "component---src-pages-articulos-ciencia-tecnologia-innovacion-tsx" */),
  "component---src-pages-articulos-ciudades-mexicanas-tsx": () => import("./../../../src/pages/articulos/ciudades_mexicanas.tsx" /* webpackChunkName: "component---src-pages-articulos-ciudades-mexicanas-tsx" */),
  "component---src-pages-articulos-derecho-movilidad-tsx": () => import("./../../../src/pages/articulos/derecho_movilidad.tsx" /* webpackChunkName: "component---src-pages-articulos-derecho-movilidad-tsx" */),
  "component---src-pages-articulos-diversificacion-sostenible-tsx": () => import("./../../../src/pages/articulos/diversificacion_sostenible.tsx" /* webpackChunkName: "component---src-pages-articulos-diversificacion-sostenible-tsx" */),
  "component---src-pages-articulos-fiscalizacion-emisiones-tsx": () => import("./../../../src/pages/articulos/fiscalizacion_emisiones.tsx" /* webpackChunkName: "component---src-pages-articulos-fiscalizacion-emisiones-tsx" */),
  "component---src-pages-articulos-hacia-pacto-verde-tsx": () => import("./../../../src/pages/articulos/hacia_pacto_verde.tsx" /* webpackChunkName: "component---src-pages-articulos-hacia-pacto-verde-tsx" */),
  "component---src-pages-articulos-inversiones-sostenibles-tsx": () => import("./../../../src/pages/articulos/inversiones_sostenibles.tsx" /* webpackChunkName: "component---src-pages-articulos-inversiones-sostenibles-tsx" */),
  "component---src-pages-articulos-irreductibles-pacto-verde-tsx": () => import("./../../../src/pages/articulos/irreductibles_pacto_verde.tsx" /* webpackChunkName: "component---src-pages-articulos-irreductibles-pacto-verde-tsx" */),
  "component---src-pages-articulos-pactar-verde-tsx": () => import("./../../../src/pages/articulos/pactar_verde.tsx" /* webpackChunkName: "component---src-pages-articulos-pactar-verde-tsx" */),
  "component---src-pages-articulos-pacto-verde-mexico-tsx": () => import("./../../../src/pages/articulos/pacto_verde_mexico.tsx" /* webpackChunkName: "component---src-pages-articulos-pacto-verde-mexico-tsx" */),
  "component---src-pages-articulos-papel-sector-empresarial-tsx": () => import("./../../../src/pages/articulos/papel_sector_empresarial.tsx" /* webpackChunkName: "component---src-pages-articulos-papel-sector-empresarial-tsx" */),
  "component---src-pages-articulos-perspectiva-cuidados-tsx": () => import("./../../../src/pages/articulos/perspectiva_cuidados.tsx" /* webpackChunkName: "component---src-pages-articulos-perspectiva-cuidados-tsx" */),
  "component---src-pages-articulos-perspectiva-norteamericana-tsx": () => import("./../../../src/pages/articulos/perspectiva_norteamericana.tsx" /* webpackChunkName: "component---src-pages-articulos-perspectiva-norteamericana-tsx" */),
  "component---src-pages-articulos-pilares-pacto-verde-tsx": () => import("./../../../src/pages/articulos/pilares_pacto_verde.tsx" /* webpackChunkName: "component---src-pages-articulos-pilares-pacto-verde-tsx" */),
  "component---src-pages-articulos-politica-agricola-tsx": () => import("./../../../src/pages/articulos/politica_agricola.tsx" /* webpackChunkName: "component---src-pages-articulos-politica-agricola-tsx" */),
  "component---src-pages-articulos-pueblos-originarios-tsx": () => import("./../../../src/pages/articulos/pueblos_originarios.tsx" /* webpackChunkName: "component---src-pages-articulos-pueblos-originarios-tsx" */),
  "component---src-pages-articulos-regeneracion-ecologica-tsx": () => import("./../../../src/pages/articulos/regeneracion_ecologica.tsx" /* webpackChunkName: "component---src-pages-articulos-regeneracion-ecologica-tsx" */),
  "component---src-pages-articulos-siete-claves-tsx": () => import("./../../../src/pages/articulos/siete_claves.tsx" /* webpackChunkName: "component---src-pages-articulos-siete-claves-tsx" */),
  "component---src-pages-articulos-trabajadores-industria-tsx": () => import("./../../../src/pages/articulos/trabajadores_industria.tsx" /* webpackChunkName: "component---src-pages-articulos-trabajadores-industria-tsx" */),
  "component---src-pages-articulos-transformacion-productiva-tsx": () => import("./../../../src/pages/articulos/transformacion_productiva.tsx" /* webpackChunkName: "component---src-pages-articulos-transformacion-productiva-tsx" */),
  "component---src-pages-articulos-transformacion-social-tsx": () => import("./../../../src/pages/articulos/transformacion_social.tsx" /* webpackChunkName: "component---src-pages-articulos-transformacion-social-tsx" */),
  "component---src-pages-articulos-transicion-energetica-tsx": () => import("./../../../src/pages/articulos/transicion_energetica.tsx" /* webpackChunkName: "component---src-pages-articulos-transicion-energetica-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-ejetematico-tsx": () => import("./../../../src/pages/ejetematico.tsx" /* webpackChunkName: "component---src-pages-ejetematico-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pactosverdes-tsx": () => import("./../../../src/pages/pactosverdes.tsx" /* webpackChunkName: "component---src-pages-pactosverdes-tsx" */),
  "component---src-pages-quehacemos-tsx": () => import("./../../../src/pages/quehacemos.tsx" /* webpackChunkName: "component---src-pages-quehacemos-tsx" */),
  "component---src-pages-quienes-somos-tsx": () => import("./../../../src/pages/quienes_somos.tsx" /* webpackChunkName: "component---src-pages-quienes-somos-tsx" */),
  "component---src-pages-reflexiones-pactoverde-tsx": () => import("./../../../src/pages/reflexiones/pactoverde.tsx" /* webpackChunkName: "component---src-pages-reflexiones-pactoverde-tsx" */),
  "component---src-pages-reflexiones-reflexion-tsx": () => import("./../../../src/pages/reflexiones/reflexion.tsx" /* webpackChunkName: "component---src-pages-reflexiones-reflexion-tsx" */),
  "component---src-pages-revista-tsx": () => import("./../../../src/pages/revista.tsx" /* webpackChunkName: "component---src-pages-revista-tsx" */)
}

